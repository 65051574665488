<section class="book_section layout_padding">
    <div class="container">
      <div class="row">
        <div class="col">
          <form [formGroup] = "ourForm" >
            <h4>
              <span class="design_dot"></span>
              BOOK APPOINTMENT
            </h4>
            <div class="form-row ">
              <div class="form-group col-lg-4">
                <label for="inputPatientName">Patient Name </label>
                <input type="text" class="form-control" id="inputPatientName" formControlName='inputPatientName' placeholder="">
                <div *ngIf="ourForm.get('inputPatientName').touched && ourForm.get('inputPatientName').errors?.required">
                  <span class="error">Patient name is required</span>
                </div>
              </div>
              <div class="form-group col-lg-4">
                <label for="inputPatientName">Patient Email </label>
                <input type="text" class="form-control" id="inputPatientEmail" formControlName='inputPatientEmail' placeholder="">
                <div *ngIf="ourForm.get('inputPatientEmail').touched && ourForm.get('inputPatientEmail').errors?.required">
                  <span class="error">Patient email is required</span>
                </div>
                <div *ngIf="ourForm.get('inputPatientEmail').touched && ourForm.get('inputPatientEmail').errors?.email">
                  <span class="error">Invalid email id</span>
                </div>
              </div>
              <div class="form-group col-lg-4">
                <label for="inputDoctorName">Doctor's Name</label>
                <select name="" class="form-control wide" id="inputDoctorName">
                  <option value="Normal distribution ">Doctor Name 1</option>
                  <option value="Normal distribution ">Doctor Name 2 </option>
                  <option value="Normal distribution ">Doctor Name 3 </option>
                </select>
              </div>
            </div>
            <div class="form-row ">
              <div class="form-group col-lg-4">
                <label for="inputPhone">Phone Number</label>
                <input type="text" class="form-control" id="inputPhone" formControlName='inputPhone' placeholder="">
                <div *ngIf="ourForm.get('inputPhone').touched && ourForm.get('inputPhone').errors?.required">
                  <span class="error">Phone Number is required</span>
                </div>
              </div>
              <div class="form-group col-lg-4">
                <label for="inputSymptoms">Symptoms</label>
                <input type="text" class="form-control" id="inputSymptoms" formControlName='inputSymptoms' placeholder="">
                <div *ngIf="ourForm.get('inputSymptoms').touched && ourForm.get('inputSymptoms').errors?.required">
                  <span class="error">Symptoms is required</span>
                </div>
              </div>
            </div>

            <div class="btn-box">
              <div class="spinner-border" *ngIf="submitting"></div>
              <button *ngIf = "!submitting" type="submit" class="btn" [disabled] = "ourForm.invalid" (click) = "submitData(ourForm.value)">Submit Now</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
