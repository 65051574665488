<!-- header section strats -->
<header class="header_section">
  <div class="container-fluid">
    <nav class="navbar navbar-expand-lg custom_nav-container">
      <a routerLink="/home">
        <!-- <h3>Thrine</h3> -->
        <img src="../../../assets/images/logo_unik.png" />
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse ml-auto" id="navbarSupportedContent">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" routerLink="/home"
              >Home </a
            >
          </li>
          <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" routerLink="/aboutus"> About </a>
          </li>
          <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" routerLink="/service"> Services </a>
          </li>
          <!-- <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" routerLink="/careers"> Careers </a>
          </li>
          <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" routerLink="/blog"> Health Blog </a>
          </li> -->
          <!-- <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" routerLink="/appointment"> Appointment </a>
          </li> -->
        </ul>
      </div>
    </nav>
  </div>
</header>
<!-- end header section -->
