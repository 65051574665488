<section class="service_section layout_padding-bottom">
  <div class="container">
    <div class="heading_container heading_center">
      <h2>
        <span class="design_dot"></span>
        Our Services
      </h2>
      <!-- <p>It is a long established fact that a reader will be distracted</p> -->
    </div>
    <div class="row">
      <div class="col-md-2"></div>
      <div class="col-md-4 mx-auto">
        <div class="box">
          <img src="../../../assets/images/s1.png" alt="" />
          <a href="#"> Pathology </a>
        </div>
      </div>
      <div class="col-md-4 mx-auto">
        <div class="box">
          <img src="../../../assets/images/s2.png" alt="" />
          <a href="#"> Diagnostic </a>
        </div>
      </div>
      <div class="col-md-2"></div>
      <div class="col-md-4 mx-auto">
        <div class="box">
          <img src="../../../assets/images/s3.png" alt="" />
          <a href="#"> Industrial Health </a>
        </div>
      </div>
      <div class="col-md-4 mx-auto">
        <div class="box">
          <img src="../../../assets/images/s4.png" alt="" />
          <a href="#"> Fitness & Nutrition </a>
        </div>
      </div>
      <div class="col-md-4 mx-auto">
        <div class="box">
          <img src="../../../assets/images/s5.png" alt="" />
          <a href="#"> Pharmacy </a>
        </div>
      </div>
      <div class="col-md-2"></div>
      <div class="col-md-4 mx-auto">
        <div class="box">
          <img src="../../../assets/images/s2.png" alt="" />
          <a href="#"> Health Camps </a>
        </div>
      </div>
      <div class="col-md-4 mx-auto">
        <div class="box">
          <img src="../../../assets/images/s1.png" alt="" />
          <a href="#"> CSR </a>
        </div>
      </div>
      <div class="col-md-2"></div>
    </div>
    <!-- <div class="btn-box">
      <a href=""> View All </a>
    </div> -->
  </div>
</section>
