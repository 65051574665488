
<section class="slider_section position-relative">
    <div
      id="carouselExampleIndicators"
      class="carousel slide"
      data-ride="carousel"
    >
      <div class="carousel-inner">
        <div class="carousel-item active">
          <div class="container">
            <div class="row">
              <div class="col-md-6">
                <div class="detail-box">
                  <div>
                    <h1>Unik medicare solution</h1>
                    <h3>
                      Where your health matters
                    </h3>
                    <!-- <a href=""> Read More </a> -->
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="img-box">
                  <img src="../assets/images/slider-img.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <div class="container">
            <div class="row">
              <div class="col-md-6">
                <div class="detail-box">
                  <div>
                    <h1>You're
OUR
First PRIORITY !</h1>
                    <!-- <p>
                      Excepturi quam cum ex, expedita inventore adipisci
                      reprehenderit incidunt doloribus delectus quod, placeat
                      nulla culpa nemo, mollitia natus! Beatae illo quas nemo
                      omnis ullam placeat, obcaecati ducimus rem id.
                      Consectetur.
                    </p> -->
                    <!-- <a href=""> Read More </a> -->
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="img-box">
                  <img src="../assets/images/slider-img.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <div class="container">
            <div class="row">
              <div class="col-md-6">
                <div class="detail-box">
                  <div>
                    <h1>Shedule an appointment today !</h1>
                    <!-- <p>
                      Excepturi quam cum ex, expedita inventore adipisci
                      reprehenderit incidunt doloribus delectus quod, placeat
                      nulla culpa nemo, mollitia natus! Beatae illo quas nemo
                      omnis ullam placeat, obcaecati ducimus rem id.
                      Consectetur.
                    </p> -->
                    <a routerLink="/appointment"> Book Now </a>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="img-box">
                  <img src="../assets/images/slider-img.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ol class="carousel-indicators">
        <li
          data-target="#carouselExampleIndicators"
          data-slide-to="0"
          class="active"
        ></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
      </ol>
    </div>
  </section>
<!-- special section -->

<section class="special_section">
  <div class="container">
    <div class="special_container">
      <div class="row">
        <div class="col-md-4">
          <div class="box b1">
            <div class="img-box">
              <svg
                version="1.1"
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 512 512"
                style="enable-background: new 0 0 512 512"
                xml:space="preserve"
              >
                <g>
                  <g>
                    <path
                      d="M0,37.647v256h376.471v-256H0z M361.412,278.588H15.059V52.706h346.353V278.588z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      d="M507.322,188.796c-28.544-11.678-59.982-11.678-88.526,0l-4.678,1.913v46.467c0,10.38,8.444,18.823,18.824,18.823
                   c2.075,0,3.765,1.689,3.765,3.765v22.588c0,11.915,7.95,22.003,18.823,25.252v8.631h15.059v-8.631
                   c10.873-3.249,18.824-13.336,18.824-25.252v-22.588c0-2.076,1.69-3.765,3.765-3.765c10.38,0,18.823-8.444,18.823-18.823V190.71
                   L507.322,188.796z M496.941,237.176c0,2.076-1.69,3.765-3.765,3.765c-10.38,0-18.824,8.444-18.824,18.824v22.588
                   c0,6.227-5.067,11.294-11.294,11.294c-6.227,0-11.294-5.067-11.294-11.294v-22.588c0-10.38-8.444-18.824-18.823-18.824
                   c-2.075,0-3.765-1.689-3.765-3.765V200.95c22.025-7.794,45.74-7.794,67.765,0V237.176z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <rect
                      x="444.235"
                      y="203.294"
                      width="39.524"
                      height="15.059"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <rect
                      x="455.529"
                      y="331.294"
                      width="15.059"
                      height="15.059"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <rect
                      x="455.529"
                      y="361.412"
                      width="15.059"
                      height="15.059"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      d="M455.529,391.529v58.353c0,5.189-4.222,9.412-9.412,9.412s-9.412-4.222-9.412-9.412v-6.099
                   c0-20.51-16.686-37.195-37.196-37.195h-23.278l-24.094-90.353h-20.843v-7.529h-15.059v7.529H67.765v-7.529H52.706v7.529H31.862
                   L0,435.719v38.634h384v-38.634l-3.753-14.072h19.262c12.207,0,22.137,9.931,22.137,22.136v6.099
                   c0,13.493,10.978,24.471,24.471,24.471s24.471-10.978,24.471-24.471v-58.353H455.529z M368.941,459.294H15.059v-15.059h323.765
                   v-15.059H17.33l26.102-97.882h297.138l26.102,97.882h-14.67v15.059h16.94V459.294z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      d="M225.155,64.718l-5.303,5.196c-8.494,8.323-19.723,12.906-31.617,12.906c-12.404,0-23.211-4.886-32.117-14.523
                   l-5.316-5.751L43.729,169.621l2.095,4.724c25.4,57.265,81.285,94.487,142.369,94.829c0.307,0.001,0.611,0.002,0.917,0.002
                   c52.352,0,87.999-25.137,108.724-46.296c22.454-22.926,32.432-45.771,32.845-46.732l2.028-4.709L225.155,64.718z M286.697,212.728
                   c-27.087,27.466-59.907,41.389-97.562,41.389c-0.285,0-0.571-0.001-0.857-0.002c-53.502-0.299-102.625-32.01-126.526-81.22
                   l88.945-88.945c10.71,9.141,23.548,13.93,37.539,13.93c13.356,0,26.082-4.335,36.533-12.332l89.856,89.162
                   C310.738,182.12,301.672,197.543,286.697,212.728z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <rect
                      x="30.118"
                      y="248.471"
                      width="15.059"
                      height="15.059"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <rect
                      x="60.235"
                      y="248.471"
                      width="15.059"
                      height="15.059"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <rect
                      x="301.177"
                      y="248.471"
                      width="15.059"
                      height="15.059"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <rect
                      x="331.294"
                      y="248.471"
                      width="15.059"
                      height="15.059"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <rect
                      x="30.118"
                      y="67.765"
                      width="45.176"
                      height="15.059"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <rect
                      x="90.353"
                      y="67.765"
                      width="15.059"
                      height="15.059"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <rect
                      x="30.118"
                      y="97.882"
                      width="30.118"
                      height="15.059"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <rect x="30.118" y="128" width="15.059" height="15.059" />
                  </g>
                </g>
                <g>
                  <g>
                    <rect
                      x="301.177"
                      y="67.765"
                      width="45.176"
                      height="15.059"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <rect
                      x="271.059"
                      y="67.765"
                      width="15.059"
                      height="15.059"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <rect
                      x="316.235"
                      y="97.882"
                      width="30.118"
                      height="15.059"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <rect x="331.294" y="128" width="15.059" height="15.059" />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      d="M82.824,377.766v-8.824H67.765v8.824c-8.763,3.108-15.059,11.477-15.059,21.293c0,12.456,10.134,22.588,22.588,22.588
                   s22.588-10.133,22.588-22.588C97.882,389.243,91.587,380.874,82.824,377.766z M75.294,406.588c-4.152,0-7.529-3.377-7.529-7.529
                   s3.377-7.529,7.529-7.529s7.529,3.377,7.529,7.529S79.446,406.588,75.294,406.588z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      d="M199.529,377.766v-8.824h-15.059v8.824c-8.763,3.108-15.059,11.477-15.059,21.293c0,12.456,10.134,22.588,22.588,22.588
                   s22.588-10.133,22.588-22.588C214.588,389.243,208.293,380.874,199.529,377.766z M192,406.588c-4.152,0-7.529-3.377-7.529-7.529
                   s3.377-7.529,7.529-7.529s7.529,3.377,7.529,7.529S196.152,406.588,192,406.588z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      d="M323.765,377.766v-8.824h-15.059v8.824c-8.763,3.108-15.059,11.477-15.059,21.293c0,12.456,10.134,22.588,22.588,22.588
                   s22.588-10.133,22.588-22.588C338.824,389.243,332.528,380.874,323.765,377.766z M316.235,406.588
                   c-4.152,0-7.529-3.377-7.529-7.529s3.377-7.529,7.529-7.529c4.152,0,7.529,3.377,7.529,7.529S320.388,406.588,316.235,406.588z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <rect x="64" y="338.824" width="15.059" height="15.059" />
                  </g>
                </g>
                <g>
                  <g>
                    <rect
                      x="94.118"
                      y="338.824"
                      width="15.059"
                      height="15.059"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <rect
                      x="124.235"
                      y="338.824"
                      width="15.059"
                      height="15.059"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <rect
                      x="154.353"
                      y="338.824"
                      width="15.059"
                      height="15.059"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <rect
                      x="184.471"
                      y="338.824"
                      width="15.059"
                      height="15.059"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <rect
                      x="214.588"
                      y="338.824"
                      width="15.059"
                      height="15.059"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <rect
                      x="244.706"
                      y="338.824"
                      width="15.059"
                      height="15.059"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <rect
                      x="274.824"
                      y="338.824"
                      width="15.059"
                      height="15.059"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <rect
                      x="304.941"
                      y="338.824"
                      width="15.059"
                      height="15.059"
                    />
                  </g>
                </g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
              </svg>
            </div>
            <div class="detail-box">
              <h4>ADVANCED TECHNOLOGY</h4>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="box b2">
            <div class="img-box">
              <svg
                id="Capa_1"
                enable-background="new 0 0 512 512"
                height="512"
                viewBox="0 0 512 512"
                width="512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="XMLID_1402_">
                  <g id="XMLID_387_">
                    <path
                      id="XMLID_390_"
                      d="m284 236h-56c-5.523 0-10 4.477-10 10v38h-38c-5.523 0-10 4.477-10 10v56c0 5.523 4.477 10 10 10h38v38c0 5.523 4.477 10 10 10h56c5.523 0 10-4.477 10-10v-38h38c5.523 0 10-4.477 10-10v-56c0-5.523-4.477-10-10-10h-38v-38c0-5.523-4.477-10-10-10zm38 68v36h-38c-5.523 0-10 4.477-10 10v38h-36v-38c0-5.523-4.477-10-10-10h-38v-36h38c5.523 0 10-4.477 10-10v-38h36v38c0 5.523 4.477 10 10 10z"
                    />
                    <path
                      id="XMLID_393_"
                      d="m462 132h-50v-30c0-5.523-4.477-10-10-10h-4v-60c0-17.645-14.355-32-32-32h-220c-17.645 0-32 14.355-32 32v60h-4c-5.523 0-10 4.477-10 10v30h-50c-27.57 0-50 22.43-50 50v95c0 5.523 4.477 10 10 10s10-4.477 10-10v-95c0-16.542 13.458-30 30-30h412c16.542 0 30 13.458 30 30v280c0 16.542-13.458 30-30 30h-412c-16.542 0-30-13.458-30-30v-95c0-5.523-4.477-10-10-10s-10 4.477-10 10v95c0 27.57 22.43 50 50 50h412c27.57 0 50-22.43 50-50v-280c0-27.57-22.43-50-50-50zm-328-100c0-6.617 5.383-12 12-12h220c6.617 0 12 5.383 12 12v60h-24v-38c0-5.523-4.477-10-10-10h-176c-5.523 0-10 4.477-10 10v38h-24zm196 60c-5.523 0-10 4.477-10 10v30h-128v-30c0-5.523-4.477-10-10-10h-4v-28h156v28zm-210 40v-20h52v20zm220 0v-20h52v20z"
                    />
                    <path
                      id="XMLID_492_"
                      d="m10 332c2.63 0 5.21-1.07 7.07-2.93s2.93-4.44 2.93-7.07-1.07-5.21-2.93-7.07-4.44-2.93-7.07-2.93-5.21 1.07-7.07 2.93-2.93 4.44-2.93 7.07 1.07 5.21 2.93 7.07 4.44 2.93 7.07 2.93z"
                    />
                  </g>
                </g>
              </svg>
            </div>
            <div class="detail-box">
              <h4>24/7 AVAILABILITY</h4>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="box b3">
            <div class="img-box">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 64 64"
                width="512"
                height="512"
              >
                <g id="doctor-covid19-coronavirus-medical-healthcare">
                  <path
                    d="M45.67,33.14l-.79-.2A5.006,5.006,0,0,0,40,29H38v-.31A8.955,8.955,0,0,0,41,22h1a3.009,3.009,0,0,0,3-3V18a2.986,2.986,0,0,0-1-2.22V9a7.008,7.008,0,0,0-7-7H33a4.989,4.989,0,0,0-4.33,2.51A2.994,2.994,0,0,0,27,4H25a5,5,0,0,0-5,5v6.78A2.986,2.986,0,0,0,19,18v1a3.009,3.009,0,0,0,3,3h1a8.955,8.955,0,0,0,3,6.69V29H24a5.006,5.006,0,0,0-4.88,3.94l-.79.2A10.968,10.968,0,0,0,10,43.81V61a1,1,0,0,0,1,1H53a1,1,0,0,0,1-1V43.81A10.968,10.968,0,0,0,45.67,33.14ZM45,39v1.09a5.6,5.6,0,0,0-2,0V39Zm-5-8a3.025,3.025,0,0,1,2.5,1.34l-1.26-.31L38,31.22V31Zm-8,2.83-4-2.4V30.05a8.9,8.9,0,0,0,8,0v1.38Zm1.2,4.72-1.2.4-1.2-.4.11-1.4L32,36.28l1.09.87Zm3.96-5.48,2.22.56-2.61,3.91-3.02-2.42ZM43,18v1a1,1,0,0,1-1,1H41V17h1A1,1,0,0,1,43,18ZM23,20H22a1,1,0,0,1-1-1V18a1,1,0,0,1,1-1h1Zm0-6v1H22V9a3.009,3.009,0,0,1,3-3h2a1,1,0,0,1,1,1,1,1,0,0,0,2,0,3.009,3.009,0,0,1,3-3h4a5,5,0,0,1,5,5v6H41V14a3.009,3.009,0,0,0-3-3H26A3.009,3.009,0,0,0,23,14Zm2,8V14a1,1,0,0,1,1-1H38a1,1,0,0,1,1,1v8a7,7,0,0,1-14,0Zm1.84,11.07,3.41,2.05-3.02,2.42-2.61-3.91ZM24,31h2v.22l-3.24.81-1.26.31A3.025,3.025,0,0,1,24,31ZM20,44a2,2,0,1,1-2,2A2.006,2.006,0,0,1,20,44Zm7.08,16H21V52a1,1,0,0,0-2,0v8H12V43.81a8.978,8.978,0,0,1,6.81-8.73l.19-.05v7.11a4,4,0,1,0,2,0V34.53l1.56-.39,3.61,5.41a.961.961,0,0,0,.68.44A.757.757,0,0,0,27,40a.977.977,0,0,0,.62-.22l1.15-.91Zm2,0,1.56-19.4,1.05.35a.986.986,0,0,0,.62,0l1.05-.35L34.92,60ZM52,60H45V52a1,1,0,0,0-2,0v8H36.92L35.23,38.87l1.15.91A.977.977,0,0,0,37,40a.757.757,0,0,0,.15-.01.961.961,0,0,0,.68-.44l3.61-5.41,1.56.39V37a2.006,2.006,0,0,0-2,2v1.81A6,6,0,0,0,38,46v2a2.006,2.006,0,0,0,2,2h1a1,1,0,0,0,0-2H40V46a4,4,0,0,1,8,0v2H47a1,1,0,0,0,0,2h1a2.006,2.006,0,0,0,2-2V46a6,6,0,0,0-3-5.19V39a2.006,2.006,0,0,0-2-2V35.03l.19.05A8.978,8.978,0,0,1,52,43.81Z"
                  />
                  <path
                    d="M29.707,23.293A1,1,0,0,0,28.3,24.709a5.246,5.246,0,0,0,7.412,0A1,1,0,0,0,34.291,23.3a3.244,3.244,0,0,1-4.582,0Z"
                  />
                  <path
                    d="M29,19a1,1,0,0,0,1-1V17a1,1,0,0,0-2,0v1A1,1,0,0,0,29,19Z"
                  />
                  <path
                    d="M35,19a1,1,0,0,0,1-1V17a1,1,0,0,0-2,0v1A1,1,0,0,0,35,19Z"
                  />
                  <path
                    d="M20,47a1,1,0,1,0-.71-1.71,1.014,1.014,0,0,0,0,1.42A1.052,1.052,0,0,0,20,47Z"
                  />
                </g>
              </svg>
            </div>
            <div class="detail-box">
              <h4>Best Doctors</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- end special section -->

<!-- about section -->
<section class="about_section layout_padding">
  <div class="container">
    <div class="heading_container heading_center">
      <h2>
        <span class="design_dot"></span>
        About Us
      </h2>
    </div>
    <div class="col-md-7 mx-auto px-0">
      <div class="img-box b2">
        <img src="../../../assets/images/about-img.png" alt="" />
      </div>
    </div>
    <div class="col-md-9 mx-auto px-0">
      <div class="detail-box">
        <p>
          Our Vision is to develop a Healthcare System where all aspects of Health can be understood, creating awareness, prevention and guiding the odd towards taking proper treatment.
        </p>
        <a routerLink="/aboutus"> Read More </a>
      </div>
    </div>
  </div>
</section>

<!-- end about section -->

<!-- service section -->

<section class="service_section layout_padding-bottom">
  <div class="container">
    <div class="heading_container heading_center">
      <h2>
        <span class="design_dot"></span>
        Our Services
      </h2>
      <!-- <p>It is a long established fact that a reader will be distracted</p> -->
    </div>
    <div class="row">
      <div class="col-md-2"></div>
      <div class="col-md-4 mx-auto">
        <div class="box">
          <img src="../../../assets/images/s1.png" alt="" />
          <a href="#"> Pathology </a>
        </div>
      </div>
      <div class="col-md-4 mx-auto">
        <div class="box">
          <img src="../../../assets/images/s2.png" alt="" />
          <a href="#"> Diagnostic </a>
        </div>
      </div>
      <div class="col-md-2"></div>
      <div class="col-md-4 mx-auto">
        <div class="box">
          <img src="../../../assets/images/s3.png" alt="" />
          <a href="#"> Industrial Health </a>
        </div>
      </div>
      <div class="col-md-4 mx-auto">
        <div class="box">
          <img src="../../../assets/images/s4.png" alt="" />
          <a href="#"> Fitness & Nutrition </a>
        </div>
      </div>
      <div class="col-md-4 mx-auto">
        <div class="box">
          <img src="../../../assets/images/s5.png" alt="" />
          <a href="#"> Pharmacy </a>
        </div>
      </div>
      <div class="col-md-2"></div>
      <div class="col-md-4 mx-auto">
        <div class="box">
          <img src="../../../assets/images/s2.png" alt="" />
          <a href="#"> Health Camps </a>
        </div>
      </div>
      <div class="col-md-4 mx-auto">
        <div class="box">
          <img src="../../../assets/images/s1.png" alt="" />
          <a href="#"> CSR </a>
        </div>
      </div>
      <div class="col-md-2"></div>
    </div>
    <!-- <div class="btn-box">
      <a href=""> View All </a>
    </div> -->
  </div>
</section>

<!-- end service section -->

<!-- book section -->

<!-- <section class="book_section layout_padding">
  <div class="container">
    <div class="row">
      <div class="col">
        <form>
          <h4>
            <span class="design_dot"></span>
            BOOK APPOINTMENT
          </h4>
          <div class="form-row">
            <div class="form-group col-lg-4">
              <label for="inputPatientName">Patient Name </label>
              <input
                type="text"
                class="form-control"
                id="inputPatientName"
                placeholder=""
              />
            </div>
            <div class="form-group col-lg-4">
              <label for="inputDoctorName">Doctor's Name</label>
              <select name="" class="form-control wide" id="inputDoctorName">
                <option value="Normal distribution ">Doctor Name 1</option>
                <option value="Normal distribution ">Doctor Name 2</option>
                <option value="Normal distribution ">Doctor Name 3</option>
              </select>
            </div>
            <div class="form-group col-lg-4">
              <label for="inputDepartmentName">Department's Name</label>
              <select
                name=""
                class="form-control wide"
                id="inputDepartmentName"
              >
                <option value="Normal distribution ">Cardiology</option>
                <option value="Normal distribution ">Surgery</option>
                <option value="Normal distribution ">First Aid</option>
              </select>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-lg-4">
              <label for="inputPhone">Phone Number</label>
              <input
                type="text"
                class="form-control"
                id="inputPhone"
                placeholder=""
              />
            </div>
            <div class="form-group col-lg-4">
              <label for="inputSymptoms">Symptoms</label>
              <input
                type="text"
                class="form-control"
                id="inputSymptoms"
                placeholder=""
              />
            </div>
            <div class="form-group col-lg-4">
              <label for="inputDate">Choose Date </label>
              <div
                class="input-group date"
                id="inputDate"
                data-date-format="mm-dd-yyyy"
              >
                <input type="text" class="form-control" readonly />
                <span class="input-group-addon date_icon">
                  <i class="fa fa-calendar" aria-hidden="true"></i>
                </span>
              </div>
            </div>
          </div>
          <div class="btn-box">
            <button type="submit" class="btn">Submit Now</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</section> -->

<!-- end book section -->

<!-- client section -->
<!-- <section class="client_section layout_padding">
  <div class="container">
    <div class="heading_container heading_center">
      <h2>
        <span class="design_dot"></span>
        Testimonial
      </h2>
    </div>
  </div>
  <div class="container px-0">
    <div
      id="customCarousel2"
      class="carousel carousel-fade"
      data-ride="carousel"
    >
      <div class="carousel-inner">
        <div class="carousel-item active">
          <div class="box">
            <div class="client_info">
              <div class="client_name">
                <h5>Clark Norris</h5>
                <h6>Default model text</h6>
              </div>
              <i class="fa fa-quote-left" aria-hidden="true"></i>
            </div>
            <p>
              editors now use Lorem Ipsum as their default model text, and a
              search for 'lorem ipsum' will uncover many web sites still in
              their infancy. Variouseditors now use Lorem Ipsum as their default
              model text, and a search for 'lorem ipsum' will uncover many web
              sites still in their infancy. Variouseditors now use Lorem Ipsum
              as their default model text, and a search for 'lorem ipsum' will
              uncover many web sites still in their infancy. Various
            </p>
          </div>
        </div>
        <div class="carousel-item">
          <div class="box">
            <div class="client_info">
              <div class="client_name">
                <h5>Chris Jonas</h5>
                <h6>Default model text</h6>
              </div>
              <i class="fa fa-quote-left" aria-hidden="true"></i>
            </div>
            <p>
              Variouseditors now use Lorem Ipsum as their default model text,
              and a search for 'lorem ipsum' will uncover many web sites still
              in their infancy. Variouseditors now use Lorem Ipsum as their
              default model text, and a search for 'lorem ipsum' will uncover
              many web sites still in their infancy. editors now use Lorem Ipsum
              as their default model text, and a search for 'lorem ipsum' will
              uncover many web sites still in their infancy.
            </p>
          </div>
        </div>
        <div class="carousel-item">
          <div class="box">
            <div class="client_info">
              <div class="client_name">
                <h5>Brad Johns</h5>
                <h6>Default model text</h6>
              </div>
              <i class="fa fa-quote-left" aria-hidden="true"></i>
            </div>
            <p>
              Variouseditors now use Lorem Ipsum as their default model text,
              and a search for 'lorem ipsum' will uncover many web sites still
              in their infancy, editors now use Lorem Ipsum as their default
              model text, and a search for 'lorem ipsum' will uncover many web
              sites still in their infancy. Variouseditors now use Lorem Ipsum
              as their default model text, and a search for 'lorem ipsum' will
              uncover many web sites still in their infancy. Various
            </p>
          </div>
        </div>
      </div>
      <div class="carousel_btn-box">
        <a
          class="carousel-control-prev"
          href="#customCarousel2"
          role="button"
          data-slide="prev"
        >
          <i class="fa fa-angle-left" aria-hidden="true"></i>
          <span class="sr-only">Previous</span>
        </a>
        <a
          class="carousel-control-next"
          href="#customCarousel2"
          role="button"
          data-slide="next"
        >
          <i class="fa fa-angle-right" aria-hidden="true"></i>
          <span class="sr-only">Next</span>
        </a>
      </div>
    </div>
  </div>
</section> -->
<!-- end client section -->

<!-- map section -->

<!-- <section class="map_section">
  <div class="map">
    <div id="googleMap"></div>
  </div>
</section> -->

<!-- end map section -->


