<!-- info section -->
<section class="info_section layout_padding">
  <div class="container">
    <div class="row">
      <div class="col-md-3">
        <div class="info_menu">
          <h5>QUICK LINKS</h5>
          <ul class="navbar-nav">
            <li class="nav-item" routerLinkActive="active">
              <a class="nav-link" routerLink="/home">Home </a>
            </li>
            <li class="nav-item" routerLinkActive="active">
              <a class="nav-link" routerLink="/aboutus"> About </a>
            </li>
            <li class="nav-item" routerLinkActive="active">
              <a class="nav-link" routerLink="/service"> Services </a>
            </li>
            <!-- <li class="nav-item" routerLinkActive="active">
              <a class="nav-link" routerLink="/careers"> Careers </a>
            </li>
            <li class="nav-item" routerLinkActive="active">
              <a class="nav-link" routerLink="/blog"> Health Blog </a>
            </li> -->
            <!-- <li class="nav-item" routerLinkActive="active">
              <a class="nav-link" routerLink="/appointment"> Appointment </a>
            </li> -->
          </ul>
        </div>
      </div>
      <div class="col-md-3">
        <div class="info_course">
          <h5>Unik medicare solution</h5>
          <p>
            Our Vision is to develop a Healthcare System where all aspects of
            Health can be understood, creating awareness, prevention and guiding
            the odd towards taking proper treatment.
          </p>
        </div>
      </div>

      <div class="col-md-5 offset-md-1">
        <div class="info_news">
          <h5>FOR ANY QUERY, PLEASE WRITE TO US</h5>
          <div class="info_contact">
            <a href="">
              <i class="fa fa-map-marker" aria-hidden="true"></i> SHOP NO:2,
              MORYA BUISNESS CENTER,NEAR PIMPRI CHINCWAD SAHAKARI BANK, THERMAX
              CHOWK,CHINCHWAD,PUNE-19
            </a>
            <a href="">
              <i class="fa fa-envelope" aria-hidden="true"></i>
              unik.healthsolutions@gmail.com
            </a>
            <a href="">
              <i class="fa fa-phone" aria-hidden="true"></i> Call : +91
              7387783239
            </a>
          </div>
          <!-- <form action="">
            <input type="text" placeholder="Enter Your email" />
            <button>Subscribe</button>
          </form> -->
        </div>
      </div>
    </div>
  </div>
</section>
<!-- footer section -->
<footer class="container-fluid footer_section">
  <div class="container">
    <p>
      &copy; 2021. All Rights Reserved By
      <a>Unik medicare solution</a>
    </p>
  </div>
</footer>
<!-- footer section -->
