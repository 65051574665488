import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';

@Component({
  selector: 'app-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.css'],
})
export class AppointmentComponent implements OnInit {
  private submissionForm: AngularFirestoreCollection<any>;
  ourForm: FormGroup;
  submitting = false;

  constructor(private fb: FormBuilder, private firestore: AngularFirestore) {}

  ngOnInit(): void {
    this.submissionForm = this.firestore.collection('submission');
    this.ourForm = this.fb.group({
      inputPatientName: ['', Validators.required],
      inputPatientEmail: ['', [Validators.required, Validators.email]],
      inputPhone: ['', Validators.required],
      inputSymptoms: ['', Validators.required],
    });
  }

  submitData(value) {
    this.submitting = true;
    this.submissionForm
      .add(value)
      .then((res) => {
        console.log('data added');
      })
      .catch((err) => {
        console.log(err);
      }).finally(() => {
        this.submitting = false;
      });
  }
}
