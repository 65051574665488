<!-- about section -->
<section class="about_section layout_padding">
  <div class="container">
    <div class="heading_container heading_center">
      <h2>
        <span class="design_dot"></span>
        About Us
      </h2>
    </div>
    <div class="col-md-7 mx-auto px-0">
      <div class="img-box b2">
        <img src="../assets/images/about-img.png" alt="" />
      </div>
    </div>
    <div class="col-md-9 mx-auto px-0">
      <div class="detail-box">
        <p>
          A Vision to develop a Healthcare System where all aspects of Health
          can be understood, creating awareness, prevention and guiding the odd
          towards taking proper treatment.
        </p>
        <p>We don’t describe our self but our service do.</p>
        <p>
          A system to blend our health with work and make it a lifestyle to be
          healthy is our vision.
        </p>
        <p>
          Pathology to screen and diagnose diseases, Annual health checks for
          the corporates to maintain and regulate health in accordance with
          factory health standards,lectures,Ambulance services, Doctors and
          Nurses for company OHC etc.
        </p>
      </div>
    </div>
  </div>
</section>

<!-- end about section -->
